import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactUs = () => (
  <Layout>
    <SEO title="About Us" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">Contact Us</h1>
            <p>
              Please contact one of our elder law attorneys with questions or
              comments.
            </p>

            <p>
              You can send an email using the form below or call 231-933-5207.
            </p>
            <p>
              Fields marked with an <span className="has-text-primary">*</span>{" "}
              are required
            </p>
            <form
              name="rysso-contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="rysso-contact" />
              <div className="field">
                <label className="label">
                  First Name<span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="First Name"
                    name="first-name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  Last Name<span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Last Name"
                    name="last-name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Company</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Company"
                    name="company"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  Email Address<span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  Phone<span className="has-text-primary">*</span>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Textarea"
                    name="message"
                  ></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button className="button is-primary">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="map-responsive">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2833.0921198588235!2d-85.59975198446604!3d44.75853597909892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x881fcdff7f61217d%3A0xf661e488fb7126e5!2sRysso%20Law%2C%20PLLC!5e0!3m2!1sen!2sus!4v1593518817056!5m2!1sen!2sus"
          width="600"
          height="450"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          title="Rysso Law Map"
        ></iframe>
      </div>
    </section>
  </Layout>
)

export default ContactUs
